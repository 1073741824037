<template>
  <div>
    <div>
        <img :src="`${$staticUrl}/p1.png`" />
    </div>
    <div class="p_content">
        <div>
            <div class="p_vice_title">
                <p>seagull</p>
                <p>国际合作伙伴</p>
            </div>
            <div class="p_vice_img">
                <div  v-for="item in wai" :key="item.name">
                    <img :src="item.src" />
                    <span>{{item.name}}</span>
                </div>
            </div>
        </div>
        <div style="margin-top:80px">
            <div class="p_vice_title">
                <p>seagull</p>
                <p>国内合作伙伴</p>
            </div>
            <div class="p_vice_img">
                <div v-for="item in nei" :key="item.name">
                    <img :src="item.src" />
                    <span>{{item.name}}</span>
                </div>
            </div>
        </div>
    </div>
  </div>
</template>

<script>
export default {
  components:{},
  props:{},
  data() {
    return {
        wai:[
            // {
            //     name:'荷兰PAL-V',
            //     src:p6
            // },
            {
                name:'以色列Urban Aeronatics',
                src:`${this.$staticUrl}/p10.png`
            },
        ],
        nei:[
            {
                name:'欧比特宇航科技股份有限公司',
                src:`${this.$staticUrl}/p2.png`
            },
            {
                name:'北京理工大学',
                src:`${this.$staticUrl}/p3.png`
            },
            {
                name:'南京航空航天大学',
                src:`${this.$staticUrl}/p4.png`
            },
            {
                name:'河南大学',
                src:`${this.$staticUrl}/p5.png`
            },
            {
                name:'哈尔滨工业大学',
                src:`${this.$staticUrl}/p7.png`
            },
            {
                name:'中航通用飞机有限责任公司',
                src:`${this.$staticUrl}/p8.png`
            },
            {
                name:'空军工程大学',
                src:`${this.$staticUrl}/p9.png`
            },
        ]
    }
  },
  methods: {

  },
  mounted() {

  },
}
</script>
<style lang='less' scoped>
.p_content{
    width: 100%;
    padding:30px 390px 100px 390px;
}
.p_vice_title{
    text-align: center;
    text-transform: uppercase;
    & p:first-child{
        letter-spacing: 11px;
        font-size: 16px;
        font-family: Montserrat-SemiBold, Montserrat;
        font-weight: 600;
        color: #474747;
        margin-bottom:20px;
    }
    & p:last-child{
        font-size: 46px;
        font-family: Montserrat-Bold, Montserrat;
        font-weight: bold;
        color: #333333;
        margin-bottom:36px;
    }
}
.p_vice_img{
    display: flex;
    flex-wrap: wrap;
    gap:5px;
    & div{
        width:274px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        font-size: 18px;
        font-weight: 500;
        color: #666666;
        margin-bottom: 10px;
    }
    & div img{
        width:90%;
        border:1px dotted #333333;
        margin-bottom: 20px;
    }
}
</style>